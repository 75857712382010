import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Globe, ExternalLink } from "react-feather"
import Stephanie from "../../images/stephanie_brennan.png"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import WorldMap from "../../components/worldmap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default class Evarvest extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showEcosystemsLink: false,
      showNextLink: false,
      showPrevLink: false,
    }

    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    // Esc key press
    if (event.keyCode === 27) {
      this.setState({
        showEcosystemsLink: true,
      })

      setTimeout(() => {
        let linkRef = document.getElementById("esc-action")
        linkRef.click()
      }, 50)
    }

    // Left Arrow key press
    if (event.keyCode === 37) {
      this.setState({
        showPrevLink: true,
      })

      setTimeout(() => {
        let prevLinkRef = document.getElementById("prev-action")
        prevLinkRef.click()
      }, 50)
    }

    // Right Arrow key press
    if (event.keyCode === 39) {
      this.setState({
        showNextLink: true,
      })

      setTimeout(() => {
        let nextLinkRef = document.getElementById("next-action")
        nextLinkRef.click()
      }, 50)
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
  }

  render() {
    return (
      <Layout>
        {this.state.showEcosystemsLink && (
          <AniLink
            id="esc-action"
            fade
            to="/community"
            duration={0.4}
          ></AniLink>
        )}
        {this.state.showPrevLink && (
          <AniLink
            id="prev-action"
            fade
            to="/community"
            duration={0.4}
          ></AniLink>
        )}
        {this.state.showNextLink && (
          <AniLink
            id="next-action"
            fade
            to="/interviews/estonia"
            duration={0.4}
          ></AniLink>
        )}
        <SEO
          title="Stephanie Brennan: Founded Evarvest in Lithuania"
          description="We spoke to Stephanie, a Female Founder and CEO of Evarvest, an investing company to understand what it is like to run a fintech startup in serving customers in Europe and globally, and how Lithuania's regulatory landscape made it a compelling choice for her to immigrate from Australia."
        />

        <div id="article-top">
          <div id="contains-map">
            <div id="lithuania_map_container">
              <WorldMap />
            </div>
          </div>
          <div id="left-col">
            <div id="left-col-content">
              {/* <div className="page-icon-container">
            <Mic className="page-icon" />
          </div> */}
              <h1 id="community-header">
                Stephanie Brennan: Founder of Evarvest
              </h1>

              <article>
                <p>
                  ​I started Evarvest in Australia in January 2018 as an
                  investing education blog with a goal to promote financial
                  literacy and wise investment decisions. I think that lack of
                  financial education is one of the biggest issues we face in
                  today’s world.{" "}
                </p>

                <p>
                  Often when you’re trying to educate yourself a lot of easily
                  accessible information and financial resources assume a
                  certain level of familiarity, which makes them difficult to
                  understand for the beginners. The goal of our investing
                  education blog was to make it easy and relatable for people to
                  understand investing and help them get started, while scaling
                  their level of knowledge over time. This approach turned out
                  to be popular — as we have grown to more than 100k views from
                  new investors (the ideal Evarvest users) from over sixty
                  countries.
                </p>

                <p>
                  After the success of our blog we decided to create a solution
                  that unites global stock exchanges in one single app and makes
                  them accessible to investors from all over the world. This
                  also stemmed from a problem I faced personally — it’s both
                  difficult and costly to access domestic and international
                  markets. In Australia, my bank used to charge me $25 in
                  brokerage fees per transaction, with a minimum transaction
                  value of $500 to access our local stock exchanges. While many
                  banks have since reduced their fees in Australia and other
                  parts of the world they're still relatively expensive,
                  especially for those who want to buy stocks in smaller
                  transactions. By comparison, to access international markets,
                  in some countries, you typically require a minimum $10,000 -
                  $20,000 to open an account.
                </p>

                <p>
                  The combination of poor resources for novice investors coupled
                  with the barrier to entry to global markets prompted us to
                  start building a service to trade stocks, rather than just
                  help people learn about them.
                </p>

                <p>
                  We decided to move our headquarters from Sydney, Australia, to
                  Vilnius, Lithuania, in order to enter the European market. We
                  researched the regulations globally for the license we needed
                  and found the EU to be the best choice, as the EU's
                  regulations allow us to obtain one license and then use the
                  ‘passporting regime’ to expand into further European countries
                  without obtaining a license in each individual EU member
                  state. This gives us the ability to reach more users in more
                  markets that are underserved and only have traditional,
                  complex and expensive ways to access domestic and
                  international stock markets.
                </p>

                <p>
                  We applied for our Lithuanian license in December 2019 and we
                  expect it to be approved this year. Simultaneously, as a part
                  of our ‘Brexit’ strategy we have also moved forward with our
                  UK license which we anticipate to be approved by the end of
                  May 2020.
                </p>

                <h3>Life in Lithuania</h3>
                <p>
                  I used to live in Sydney, and while Vilnius is a smaller city,
                  it really is a nice place to live. Compared to Australia it's
                  colder — although I'm unusual in that I love the cold and the
                  snow. We've been living in Lithuania for nearly 2 years and we
                  find it a really welcoming country.
                </p>

                <p>
                  In terms of language, most people here speak English, though
                  we've been trying to learn Lithuanian which is a bit more
                  challenging to pick up than I expected. Mornings in the city
                  are quite peaceful and it often gets livelier in the day.
                  There's also a lot of government-organised initiatives helping
                  to develop the community spirit here, and at the end of each
                  summer there are lots of events like music festivals and other
                  recreational activities. Also we learnt that Lithuanian people
                  are quite similar to Australians and in many ways we share the
                  same sense of humour.
                </p>

                <h3>The Product</h3>

                <p>
                  We're building a stock trading app directed to the new
                  generation of investors — think of it like the Spotify of
                  global stock trading, where we unite many stock exchanges into
                  one single app and remove barriers to entry such as difficult
                  sign-up procedures or high fees. Once we launch, we’ll offer
                  our users commission-free and/or low-cost access to over 30
                  stock exchanges all over the world.
                </p>

                <ul>
                  <li>
                    <strong>We're commission-free in the US</strong>, and have
                    no minimum transaction amount, which means that when you're
                    buying a share for $1, you’ll pay precisely this amount.
                    Using our app, you can track news and updates on your
                    portfolio and other stocks you might be interested in.
                    Additionally, you’ll have access to our educational library
                    from within the app.
                  </li>

                  <li>
                    <strong>Social Investing</strong> is another interesting
                    feature we will have within our app that enables you to
                    follow the portfolio of your friends or top performing
                    investors. At Evarvest we believe in making investing
                    social, empowering young investors to learn from others,
                    educated themselves and in turn make wise financial
                    decisions, helping them to secure their financial future.
                  </li>

                  <li>
                    <strong>Fractional Investing</strong> is one of the upcoming
                    features that I’m very excited about, which aligns with our
                    goal of removing barriers to entry. Fractional investing
                    will allow our users to buy partial shares in their
                    favourite companies, meaning if you wanted to buy a more
                    expensive share, such as Amazon, you could by $10 or $100
                    worth instead of the full share, giving you a smaller entry
                    price.
                  </li>
                </ul>

                <p>
                  As for the future, we have a couple of other offerings we want
                  to expand into, primarily focused on retirement savings, as
                  traditional pension funds underperform and are often
                  misaligned with the investment choices/goals of the next
                  generation of investors.
                </p>

                <h3>The Immigration Process</h3>
                <p>
                  After applying for the Lithuanian Startup Visa programme run
                  by Startup Lithuania, we had our first interview with them in
                  April 2018, moved to Lithuania in July 2018 and officially
                  received the startup visa in September 2018. The application
                  process is as follows:
                </p>

                <ol>
                  <li>
                    You fill out an online application describing your company
                    and send through supporting information which is reviewed by
                    a committee comprised of relevant experts
                  </li>
                  <li>
                    If they think your company is a good fit, you move onto a
                    recorded video interview where you pitch your company. The
                    committee makes their final decision based on your companies
                    eligibility to be considered a startup (growth potential,
                    impact in Lithuania), as well as your background
                  </li>
                  <li>
                    If you're accepted, the next step is to liaise remotely with
                    various organizations to help you get set up in the country,
                    like immigration and real estate.
                  </li>
                </ol>

                <h3>Fundraising</h3>
                <p>
                  In July 2019 we closed our first crowdfunding round reaching
                  304% of our initial target. We had individual investors from
                  Lithuania as well as people from Europe, Australia and beyond.
                  The local startup ecosystem has been a great help to us
                  overall. We found crowdfunding the right fit for us, since
                  we’re aiming to expand internationally, having shareholders
                  distributed internationally helps us to better understand
                  market dynamics in more countries. At this point we are
                  focused on finalising our UK and EU license procedures as well
                  as growing our community and user base.
                </p>

                <h3>Words of Advice</h3>
                <p>
                  My advice for other entrepreneurs would be first to understand
                  what their goal and vision for the company is and to make all
                  decisions on the basis of where you want to end up. For
                  immigrant founders specifically, I think the key thing is to
                  figure out which country makes the most sense for your company
                  based on geographic location, size, and specific legal and
                  cultural considerations, as well as a strong startup scene
                  maximising the ability to successfully build and launch a new
                  company.
                </p>
              </article>
            </div>
          </div>
          <div id="right-col">
            <div className="contact-card">
              <div className="contact-image">
                <img
                  src={Stephanie}
                  alt="Logo"
                  style={{ transform: "scale(1.2)" }}
                />
              </div>
              <div className="contact-name">Stephanie Brennan</div>
              <div className="contact-bio">Founder and CEO at Evarvest</div>
              <div className="contact-links">
                <div className="contact-link-container">
                  <OutboundLink
                    href="https://www.evarvest.com"
                    rel="noreopen noreferer"
                    className="contact-link"
                  >
                    <div className="contact-icon-container">
                      <ExternalLink className="contact-icon" />
                    </div>
                    Evarvest
                  </OutboundLink>
                  <OutboundLink
                    href="https://www.linkedin.com/in/stephaniesbrennan/"
                    rel="noreopen noreferer"
                    className="contact-link"
                  >
                    <div className="contact-icon-container">
                      <ExternalLink className="contact-icon" />
                    </div>
                    LinkedIn
                  </OutboundLink>
                </div>
              </div>
            </div>
            <AniLink fade to="/ecosystems/lithuania" duration={0.4}>
              <div className="long-container">
                <div className="item-header">
                  <div className="long-icon-container">
                    <Globe className="slack-icon" />
                  </div>
                  Lithuania's Ecosystem
                  <div className="item-arrow">→</div>
                </div>
                <div className="item-description"></div>
              </div>
            </AniLink>
            <AniLink fade to="/startup-visas/lithuania" duration={0.4}>
              <div className="long-container">
                <div className="item-header">
                  <div className="long-icon-container">
                    <Globe className="slack-icon" />
                  </div>
                  Get Startup Visa
                  <div className="item-arrow">→</div>
                </div>
                <div className="item-description"></div>
              </div>
            </AniLink>
          </div>
        </div>

        <div className="interview-body"></div>
      </Layout>
    )
  }
}
